import React, { useState } from 'react';
import { signUp, login } from '../api';
import { useConfig } from '../contexts/ConfigContext';

function Auth({ setLoggedIn, setLoggedInUser }) {
    const { API_URL } = useConfig();

    console.log("Auth URL: ",API_URL)
    const [isLogin, setIsLogin] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateInputs = (username, password) => {
        const newErrors = {};

        if (!username) newErrors.username = "Username is required";
        // Add more validation checks if needed e.g. regex for specific format

        if (!password) newErrors.password = "Password is required";
        else if (password.length < 8) newErrors.password = "Password must be at least 8 characters";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;  // Return true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, password } = e.target.elements;

        if (!validateInputs(username.value, password.value)) return;

        setLoading(true);
        try {
            if (isLogin) {
                await login(API_URL, { username: username.value, password: password.value });
                setLoggedInUser(username.value);
                setLoggedIn(true);
            } else {
                await signUp(API_URL, { username: username.value, password: password.value });
                setIsLogin(true);
            }
        } catch (error) {
            console.error('Authentication error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-white">
                    <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
                    InvoiSight 
                </a>
                <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                            {isLogin ? 'Sign in to your account' : 'Create a new account'}
                        </h1>
                        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                            <div>
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-white">Username</label>
                                <input 
                                    type="text" 
                                    name="username" 
                                    id="username" 
                                    placeholder="Username" 
                                    autoComplete="off"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                />
                                {errors.username && <p className="text-red-500 text-xs mt-1">{errors.username}</p>}
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Password</label>
                                <input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    placeholder="••••••••" 
                                    autoComplete="new-password"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                />
                                {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
                            </div>
                            <button 
                                type="submit" 
                                disabled={loading} 
                                className={`w-full text-white bg-primary-600 ${loading ? 'opacity-50' : 'hover:bg-primary-700'} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                            >
                                {loading ? 'Processing...' : isLogin ? 'Sign in' : 'Sign Up'}
                            </button>
                            <p className="text-sm font-light text-gray-400">
                                {isLogin ? "Don't have an account yet?" : 'Already have an account?'} 
                                <button onClick={() => setIsLogin(!isLogin)} className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                                    {isLogin ? 'Sign Up' : 'Sign In'}
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Auth;
