import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ExpenseSummary = ({ totalSpent, totalTax, timePeriod }) => {
  return (
    <div className="bg-gray-800 p-4 sm:p-6 md:p-8 lg:p-6 rounded-xl shadow-md">
      <h6 className="text-white text-lg mb-2">
        Expense Summary 
      </h6>
      <p className="text-gray-200 mb-1">
        <strong>Total Amount Spent:</strong> ${totalSpent}
      </p>
      <p className="text-gray-200">
        <strong>Total Tax Paid:</strong> ${totalTax}
      </p>
    </div>
  );
};

export default ExpenseSummary;
