import { Box, useTheme } from "@mui/material";
import { Document, Page } from 'react-pdf';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ExpenseSummary from '../../components/ExpenseSummary';
import { useConfig } from "../../contexts/ConfigContext";

const Invoices = ({ setLoggedIn, loggedInUser }) => {
  const [isPdfVisible, setIsPdfVisible] = useState(false);
  const [pdfURL, setPdfURL] = useState(null);
  const [totalSpent, setTotalSpent] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [receiptId, setReceiptId] = useState("");
  const [uploadedData, setUploadedData] = useState(null);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null); // NEW: State to track the editing row.
  const [editedAmount, setEditedAmount] = useState('');
  const [editedInvoiceItem, setEditedInvoiceItem] = useState('');
  const [editedCompany, setEditedCompany] = useState('');
  const [editedDate, setEditedDate] = useState('');
  const [editedCategory, setEditedCategory] = useState('');
  const [editedTaxAmount, setEditedTaxAmount] = useState('');
  const [editedTaxPercentage, setEditedTaxPercentage] = useState('');
  const { API_URL } = useConfig();
  console.log('API_URL in Invoices:', API_URL);

  //const API_URL = 'https://invoisight-dev-api.invoisight.com';
  useEffect(() => {
    if (!API_URL) return;
    const fetchData = async () => {
      try {
        const response = await getExtractedData();
        if (Array.isArray(response.data.data)) {

          // Process the data
          const processedData = response.data.data.map(receipt => {
            let extractedData;

            if (receipt?.invoice_item) {
              extractedData = receipt;
            } else if (receipt?.extracted_data) {
              if (typeof receipt.extracted_data === "string") {
                try {
                  const parsedData = JSON.parse(receipt.extracted_data);
                  extractedData = Array.isArray(parsedData) ? parsedData[0] : parsedData;
                } catch (error) {
                  console.error("Error parsing extracted_data:", receipt.extracted_data);
                  return null;
                }
              } else if (typeof receipt.extracted_data === "object") {
                extractedData = receipt.extracted_data;
              } else {
                console.error("Unexpected format for extracted_data:", receipt);
                return null;
              }
            } else {
              console.error("Unexpected receipt format:", receipt);
              return null;
            }

            if (!extractedData.invoice_item) {
              console.warn("Extracted data lacks 'invoice_item':", extractedData);
              console.warn("Original receipt data:", receipt);

              return null;
            }

            return { ...receipt, ...extractedData };
          }).filter(Boolean); // Filters out null values

          setReceipts(processedData);  // Set the processed data to state

        } else {
          console.warn("Expected an array from getExtractedData, but got:", response.data.data);
        }
      } catch (error) {
        console.error('Error fetching extracted data:', error);
      }
    };
    fetchData();
  }, [API_URL]);
  useEffect(() => {
    function parseCurrency(value) {
      const parsed = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return isNaN(parsed) ? 0 : parsed;
    }

    const newTotalSpent = receipts.reduce((acc, receipt) => acc + parseCurrency(receipt.Amount || '0'), 0);
    const newTotalTax = receipts.reduce((acc, receipt) => acc + parseCurrency(receipt.tax_amount || '0'), 0);

    setTotalSpent(newTotalSpent);
    setTotalTax(newTotalTax);
  }, [receipts]);

  const buttonStyle = {
    margin: '0 5px',
    padding: '5px 15px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007BFF',  // Bootstrap primary color for demonstration
    color: 'white',
    cursor: 'pointer',
  };
  const deleteButtonStyle = {
    ...buttonStyle,  // <-- Spread the existing button styles
    backgroundColor: '#e74c3c',  // <-- Red color for the delete button
  };


  const inputStyle = {
    padding: '5px 10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    color: '#333', // Making text color dark for visibility
    width: '90%',  // Adjust width to occupy most of the cell space
    backgroundColor: '#f9f9f9', // Slight gray to distinguish input from background
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },

    {
      field: 'invoice_item',
      headerName: 'Invoice Item',
      width: 200,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedInvoiceItem}
          onChange={(e) => setEditedInvoiceItem(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },

    {
      field: 'Amount',
      headerName: 'Amount',
      width: 130,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedAmount}
          onChange={(e) => setEditedAmount(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },

    {
      field: 'Company_name',
      headerName: 'Company',
      width: 200,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedCompany}
          onChange={(e) => setEditedCompany(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },

    {
      field: 'invoice_date',
      headerName: 'Date',
      width: 130,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedDate}
          onChange={(e) => setEditedDate(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },

    {
      field: 'category',
      headerName: 'Category',
      width: 150,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedCategory}
          onChange={(e) => setEditedCategory(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },
    {
      field: 'tax_percentage',
      headerName: 'Tax Percentage',
      width: 130,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedTaxPercentage}
          onChange={(e) => setEditedTaxPercentage(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },

    {
      field: 'tax_amount',
      headerName: 'Tax Amount',
      width: 130,
      renderCell: (params) => editingIndex === params.row.id ? (
        <input
          type="text"
          value={editedTaxAmount}
          onChange={(e) => setEditedTaxAmount(e.target.value)}
          style={inputStyle}
        />
      ) : params.value
    },
    {
      field: 'receipt_url',
      headerName: 'Receipt URL',
      width: 200,
      renderCell: (params) => {
        console.log("Row Data:", params.row);  // <-- Add this line
        return (
          <a href={params.row.s3_url} target="_blank" rel="noopener noreferrer">
            View Receipt
          </a>
        );
      }

    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {editingIndex === params.row.id ? (
            <button onClick={() => handleSave(params.row.id)} style={buttonStyle}>Save</button>
          ) : (
            <>
              <button onClick={() => handleEdit(params.row.id)} style={buttonStyle}>
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button onClick={() => handleDelete(params.row.id)} style={deleteButtonStyle}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </>
          )}
        </div>
      )
    }
  ];




  const handleDelete = async (id) => {
    try {
      // First, find the receipt that matches the provided ID
      const receiptToDelete = receipts.find(receipt => receipt.id === id);

      if (!receiptToDelete) {
        console.error(`Couldn't find receipt with id: ${id}`);
        return;
      }

      // Construct the URL to delete the receipt from the backend
      const fileName = receiptToDelete.s3_url.replace("https://invoisight.s3.amazonaws.com/", "");
      const [cleanFileName] = fileName.split("?");

      // Make the delete request to the backend
      const response = await axios.delete(`${API_URL}/delete-receipt/${cleanFileName}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      // Check if the deletion was successful
      if (response.status === 200) {
        console.log("Receipt deleted successfully from backend.");

        // Remove the receipt from local state
        const updatedReceipts = receipts.filter(receipt => receipt.id !== id);
        setReceipts(updatedReceipts);

        alert("Receipt deleted successfully!");  // Notify the user
      } else {
        console.error("Failed to delete receipt from backend:", response.data);
        alert("Error deleting receipt. Please try again.");  // Notify the user
      }
    } catch (error) {
      console.error('Error deleting receipt from backend:', error);
      alert("Error deleting receipt. Please try again.");  // Notify the user
    }
  };


  const uploadReceipt = (formData) => {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentageProgress = Math.floor((loaded / total) * 100);
        setUploadProgress(percentageProgress); // Update the state with the progress value.
        console.log(percentageProgress);
      }
    };

    console.log('Headers before API call: ', axios.defaults.headers);
    return axios.post(`${API_URL}/extract-from-document`, formData, config);
  };
  const getExtractedData = () => {
    if (!API_URL) {
      console.error("API_URL is null");
      return Promise.reject(new Error("API_URL is null"));
    }
    return axios.get(`${API_URL}/my-extracted-data`);
  };
  const handleUpload = async () => {
    setIsLoading(true);
    try {
      if (!file) {
        console.warn("No file selected for upload.");
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadReceipt(formData);
      console.log("Upload response:", response.data);
      // Set the receiptId from the response
      if (response.data && response.data.receipt_id) {
        setReceiptId(response.data.receipt_id);
      }
      // Check if the uploaded data is valid and set it
      if (response.data && response.data.results) {
        const data = JSON.parse(response.data.results);
        setUploadedData(data);
      }
      setPdfURL(response.data.s3_url)
      // Re-fetch all data after upload.
      const { data } = await getExtractedData();
      console.log("Extracted data after upload:", data);

      if (!Array.isArray(data.data)) {
        console.error("Fetched data is not an array:", data.data);
        return;
      }

      setReceipts(data.data); // Update the state with fresh data.
      console.log("Receipts after setting:", data.data);
      setUploadProgress(0);
      setIsLoading(false);
      setUploadModalOpen(true);
      setIsPdfVisible(true);
    } catch (error) {
      console.error('Error uploading receipt:', error);
    }
  };
  function UploadModal({ isOpen, onClose, onSave, defaultData, receiptId }) {
    const [localData, setLocalData] = useState({
      invoice_item: defaultData?.invoice_item || '',
      Amount: defaultData?.Amount || '',
      Company_name: defaultData?.Company_name || '',
      invoice_date: defaultData?.invoice_date || '',
      category: defaultData?.category || '',
      tax_amount: defaultData?.tax_amount || '',
      tax_percentage: defaultData?.tax_percentage || '',
    });
    const [pageNumber, setPageNumber] = useState(1);  // Current page
    const [numPages, setNumPages] = useState(null);  // Total number of pages

    const handleSubmit = () => {
      saveUpdatedReceipt(localData, receiptId); // Use the data from modal and the receiptId
      window.location.reload();
      onClose();
    };
    return (
      <div>

        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center mt-8 z-50 ${isOpen ? 'block' : 'hidden'} `}>


          <div className="bg-white p-4 rounded shadow-lg w-3/4 z-10 flex">
            {/* PDF Side */}
            <div className="w-1/2 p-6 bg-gray-800 text-white rounded shadow-lg flex flex-col">
              <h2 className="text-center mb-4">Invoice view</h2>

              <div className="flex-1 flex justify-center items-center  p-4">
                <Document
                  file={pdfURL}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  className="w-full h-full"
                >
                  <Page pageNumber={pageNumber} width={window.innerWidth * 0.3} />
                </Document>
              </div>

              {/* Navigation controls */}
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() => setPageNumber(prevPage => Math.max(prevPage - 1, 1))}
                  disabled={pageNumber === 1}
                  className="text-blue-500 hover:text-blue-600"
                >
                  ← Previous
                </button>

                <span>Page {pageNumber} of {numPages}</span>

                <button
                  onClick={() => setPageNumber(prevPage => Math.min(prevPage + 1, numPages))}
                  disabled={pageNumber === numPages}
                  className="text-blue-500 hover:text-blue-600"
                >
                  Next →
                </button>
              </div>
            </div>


            {/* Form Side */}
            <div className="w-1/2 bg-gray-800  text-white p-6 rounded shadow-lg">
              <h2 className="text-center mb-4">Upload Details</h2>

              <label>Invoice Items:</label>
              <input type="text" value={localData.invoice_item} onChange={(e) => setLocalData(prev => ({ ...prev, invoice_item: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />
              <label>Receipt ID:</label>   {/* New label for receipt id */}
              <input type="text" value={receiptId} disabled className="w-full p-2 mb-2 bg-gray-700 text-white" /> {/* New input field for receipt id */}

              <label>Amount:</label>
              <input type="text" value={localData.Amount} onChange={(e) => setLocalData(prev => ({ ...prev, Amount: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

              <label>Company Name:</label>
              <input type="text" value={localData.Company_name} onChange={(e) => setLocalData(prev => ({ ...prev, Company_name: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

              <label>Invoice Date:</label>
              <input type="text" value={localData.invoice_date} onChange={(e) => setLocalData(prev => ({ ...prev, invoice_date: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

              <label>Category:</label>
              <input type="text" value={localData.category} onChange={(e) => setLocalData(prev => ({ ...prev, category: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

              <label>Tax Amount:</label>
              <input type="text" value={localData.tax_amount} onChange={(e) => setLocalData(prev => ({ ...prev, tax_amount: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />
              <label>Tax Percentage:</label>
              <input type="text" value={localData.tax_percentage} onChange={(e) => setLocalData(prev => ({ ...prev, tax_percentage: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />
              <div className="mt-4 flex justify-between">
                <button
                  onClick={() => {
                    handleDelete(receiptId);
                    onClose();
                  }}
                  className="bg-red-500 text-white p-2 rounded hover:bg-red-600">
                  Cancel
                </button>
                <button onClick={handleSubmit} className="bg-green-500 text-white p-2 rounded hover:bg-green-600">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
  function UploadModals({ isOpen, onClose, onSave, defaultData, receiptId }) {
    const [localData, setLocalData] = useState({
      invoice_item: defaultData?.invoice_item || '',
      Amount: defaultData?.Amount || '',
      Company_name: defaultData?.Company_name || '',
      invoice_date: defaultData?.invoice_date || '',
      category: defaultData?.category || '',
      tax_amount: defaultData?.tax_amount || '',
      tax_percentage: defaultData?.tax_percentage || '',
    });

    const handleSubmit = () => {
      saveUpdatedReceipt(localData, receiptId); // Use the data from modal and the receiptId
      window.location.reload();
      onClose();
    };

    return (
      <div className={`fixed top-0 left-0 w-full h-full flex flex-row justify-center items-center mt-8 ${isOpen ? 'block' : 'hidden'} z-50`}>


        <div className="flex-1 flex justify-center items-center border-red-500 border-2 p-4">
          <Document file={pdfURL} className="w-full h-full">
            <Page pageNumber={1} width={window.innerWidth * 0.3} />
          </Document>
        </div>

        <div className="flex-1 mt-24 mx-4 bg-gray-800 text-white p-6 rounded shadow-lg">

          <h2 className="text-center mb-4">Upload Details</h2>

          <label>Invoice Items:</label>
          <input type="text" value={localData.invoice_item} onChange={(e) => setLocalData(prev => ({ ...prev, invoice_item: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />
          <label>Receipt ID:</label>   {/* New label for receipt id */}
          <input type="text" value={receiptId} disabled className="w-full p-2 mb-2 bg-gray-700 text-white" /> {/* New input field for receipt id */}

          <label>Amount:</label>
          <input type="text" value={localData.Amount} onChange={(e) => setLocalData(prev => ({ ...prev, Amount: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

          <label>Company Name:</label>
          <input type="text" value={localData.Company_name} onChange={(e) => setLocalData(prev => ({ ...prev, Company_name: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

          <label>Invoice Date:</label>
          <input type="text" value={localData.invoice_date} onChange={(e) => setLocalData(prev => ({ ...prev, invoice_date: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

          <label>Category:</label>
          <input type="text" value={localData.category} onChange={(e) => setLocalData(prev => ({ ...prev, category: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />

          <label>Tax Amount:</label>
          <input type="text" value={localData.tax_amount} onChange={(e) => setLocalData(prev => ({ ...prev, tax_amount: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />
          <label>Tax Percentage:</label>
          <input type="text" value={localData.tax_percentage} onChange={(e) => setLocalData(prev => ({ ...prev, tax_percentage: e.target.value }))} className="w-full p-2 mb-2 bg-gray-700 text-white" />
          <div className="mt-4 flex justify-between">
            <button
              onClick={() => {
                handleDelete(receiptId);
                onClose();
              }}
              className="bg-red-500 text-white p-2 rounded hover:bg-red-600">
              Cancel
            </button>
            <button onClick={handleSubmit} className="bg-green-500 text-white p-2 rounded hover:bg-green-600">
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  const handleEdit = (id) => {
    const receipt = receipts.find(receipt => receipt.id === id);

    if (!receipt) {
      console.error("Couldn't find receipt with id:", id);
      return;
    }

    let extractedData;

    if (receipt.invoice_item) {
      extractedData = receipt;
    } else if (receipt.extracted_data) {
      try {
        if (typeof receipt.extracted_data === "string") {
          const parsedData = JSON.parse(receipt.extracted_data);
          extractedData = Array.isArray(parsedData) ? parsedData[0] : parsedData;
        } else if (typeof receipt.extracted_data === "object") {
          extractedData = receipt.extracted_data;
        } else {
          console.error("Unexpected format for extracted_data:", receipt);
          return;
        }
      } catch (error) {
        console.error("Error parsing extracted_data:", receipt.extracted_data);
        return;
      }
    } else {
      console.error("Unexpected receipt format:", receipt);
      return;
    }

    setEditingIndex(id); // Set the editing index to the receipt's id.
    setEditedInvoiceItem(extractedData.invoice_item || '');
    setEditedAmount(extractedData.Amount || '');  // Set the current value
    setEditedCompany(extractedData.Company_name || '');
    setEditedDate(extractedData.invoice_date || '');
    setEditedCategory(extractedData.category || '');
    setEditedTaxAmount(extractedData.tax_amount || '');
    setEditedTaxPercentage(extractedData.tax_percentage || '');
  };

  function exportToCSV(rows, filename = 'export.csv') {
    const headers = ['ID', 'Invoice Item', 'Amount', 'Company', 'Date', 'Category', 'Tax Amount', 'Receipt URL'];

    const csvData = rows.map(row => [
      row.id,
      row.invoice_item,
      row.Amount,
      row.Company_name,
      row.invoice_date,
      row.category,
      row.tax_amount,
      row.s3_url
    ]);

    const csv = [
      headers.join(','),
      ...csvData.map(row => row.map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(','))
    ].join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const saveUpdatedReceipt = async (data, index) => {
    const updatedReceipts = [...receipts];
    const currentReceipt = updatedReceipts.find(receipt => receipt.id === index);

    if (!currentReceipt) {
      console.error(`Couldn't find receipt with id: ${index}`);
      return;
    }

    Object.assign(currentReceipt, data); // Assign the data to the current receipt

    // Now update the state
    setReceipts(updatedReceipts);
    console.log("Updated Receipts:", updatedReceipts);

    // Send data to backend
    try {
      const new_data = { results: data };
      const fileName = currentReceipt.s3_url.replace("https://invoisight.s3.amazonaws.com/", "");
      const [cleanFileName] = fileName.split("?");
      const response = await axios.put(`${API_URL}/edit-receipt/${cleanFileName}?new_data=${encodeURIComponent(JSON.stringify(new_data))}`, {
        new_file: '' // Since you're sending an empty new_file in the working curl request
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.message === "Receipt updated successfully") {
        console.log("Data updated successfully in backend.");
        console.log("Backend response:", response.data);
        alert("Receipt updated successfully!");
      } else {
        console.error("Failed to update data in backend:", response.data);
        alert("Error updating receipt. Please try again.");
      }
    } catch (error) {
      console.error('Error sending updated data to backend:', error);
      alert("Error updating receipt. Please try again.");
    }
  };


  const handleSave = async (index) => {
    const data = {
      invoice_item: editedInvoiceItem,
      Amount: editedAmount,
      Company_name: editedCompany,
      invoice_date: editedDate,
      category: editedCategory,
      tax_amount: editedTaxAmount,
      tax_percentage: editedTaxPercentage
    };
    saveUpdatedReceipt(data, index);
    setEditingIndex(null);
  };


  return (
    <Box m="20px" className="sm:m-10 md:m-15 lg:m-20">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />
      <div className="flex justify-between gap-6">
        <div className="max-w-lg bg-gray-800 p-4 sm:p-6 md:p-8 lg:p-6 rounded-xl shadow-md">
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            className="p-2 w-full mb-4 text-gray-200" // Added text-gray-200 for light text color
          />
          <button
            onClick={handleUpload}
            className="p-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 transition"
          >
            Upload
          </button>
          {/* New Spinner */}
          {isLoading && (
            <div role="status" className="mx-auto mt-4">
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>)}
          {isPdfVisible && isUploadModalOpen && (
            <UploadModal
              isOpen={isUploadModalOpen}
              onClose={() => {
                setUploadModalOpen(false);
                setUploadedData(null); // Reset uploaded data after closing the modal
                setIsPdfVisible(false); // Hide PDF when modal is closed
              }}
              onSave={(data) => {
                console.log("Uploaded Data:", data);
                // Here you can send the data to the backend or any other action you want.
              }}
              defaultData={uploadedData}
              receiptId={receiptId}
            />
          )}
          {uploadProgress > 0 && uploadProgress < 100 && (
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200">
                    Upload Progress
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-gray-200">
                    {uploadProgress}%
                  </span>
                </div>
              </div>
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                <div style={{ width: `${uploadProgress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"></div>
              </div>
            </div>
          )}
        </div>

        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ padding: '20px' }}>
            <ExpenseSummary totalSpent={totalSpent.toFixed(2)} totalTax={totalTax.toFixed(2)} timePeriod="August 2023" />
          </div>
        </ThemeProvider>
      </div>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <div className="flex justify-end mb-4"> {/* Flex container to position button */}
          <button
            onClick={() => exportToCSV(receipts)}
            className="p-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 active:bg-blue-800 transition duration-150"
          >
            Export to CSV
          </button>
        </div>
        <DataGrid checkboxSelection rows={receipts} columns={columns} />
      </Box>
    </Box>
  );
};

export default Invoices;
