import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from './contexts/ConfigContext';
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import { setAuthToken } from './api';
import React, { useState, useEffect } from 'react';
import Auth from './components/Auth'
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState('');

  function InvoiceWrapper(props) {
    return <Invoices {...props} />;
  }
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
      setAuthToken(token); // Ensure you still need this for setting up API requests
    }
  }, []);

  if (!loggedIn) {
    return <Auth setLoggedIn={setLoggedIn} setLoggedInUser={setLoggedInUser} />;
  }

  return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar loggedInUser={loggedInUser} setIsSidebar={setIsSidebar} setLoggedIn={setLoggedIn} setAuthToken={setAuthToken} />

              <Routes>
                <Route path="/" element={<InvoiceWrapper setLoggedIn={setLoggedIn} setLoggedInUser={setLoggedInUser} />} />
                <Route path="/invoices" element={<InvoiceWrapper setLoggedIn={setLoggedIn} setLoggedInUser={setLoggedInUser} />} />

                <Route path="/form" element={<Form />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default App;
