import { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useConfig } from '../contexts/ConfigContext';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13, // Increasing the height
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function UserCredits() {
  const [credits, setCredits] = useState(0);

  const { API_URL } = useConfig();
  useEffect(() => {
    const fetchCredits = async () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        try {
            const response = await axios.get(`${API_URL}/get-credits`, config);
            setCredits(response.data.credits);
        } catch (error) {
            console.error("Failed to fetch credits: ", error);
        }
    };

    fetchCredits();
}, []);
const progress = (credits / 30) * 100;

  return (
    <Box display="flex" alignItems="center" gap={1} sx={{ flexGrow: 1, maxWidth: '300px', minWidth: 250}}>
      <Box width="80%" mr={1}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography variant="body2">{credits} credits left</Typography>
    </Box>
  );
}
