import { createContext, useContext, useState, useEffect } from 'react';

// Initialize with default values
const ConfigContext = createContext({
    API_URL: "https://invoisight-dev-api.invoisight.com" 
});

export const ConfigProvider = ({ children }) => {
  const [API_URL, setAPI_URL] = useState(null); // Use null as an initial value to indicate "not loaded"

  useEffect(() => {
    // Switch to .json for security
    fetch("/config.json")
      .then((response) => {
        if (!response.ok) {
            throw new Error('Failed to fetch configuration');
        }
        return response.json();
      })
      .then((config) => {
        console.log("Parsed Config:", config);
        setAPI_URL(config.API_URL);
        console.log("Set API_URL:", config.API_URL);
      })
      .catch((error) => console.error("Failed to fetch API configuration.", error));
  }, []);

  return (
    <ConfigContext.Provider value={{ API_URL }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
      throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
