import axios from 'axios';


const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

const signUp = (API_URL, userData) => axios.post(`${API_URL}/auth`, userData);
const login = async (API_URL, credentials) => {
    const formData = new URLSearchParams();
    formData.append('username', credentials.username);
    formData.append('password', credentials.password);
    
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    
    const response = await axios.post(`${API_URL}/auth/token`, formData, config);
    if (response.data && response.data.access_token) {
        setAuthToken(response.data.access_token);
        localStorage.setItem('token', response.data.access_token);
    }
    return response;
};
const uploadReceipt = (API_URL, formData) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
        }
    };
    return axios.post(`${API_URL}/extract-from-document`, formData, config);
};
const getExtractedData = (API_URL) => axios.get(`${API_URL}/my-extracted-data`);

export { signUp, login, uploadReceipt, getExtractedData, setAuthToken };
